export const env = {
  DEV: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev",
  },
  DANTE: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-dante`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-dante",
  },
  FRAN_CAJAL: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-fran`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-fran",
  },
  ROQUE: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-roque`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-roque",
  },
  LEO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-leo`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-leo",
  },
  LU: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-lu`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-lu",
  },
  LUCHO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-lucho`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-lucho",
  },
  JUSTO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-justo`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-justo",
  },
  LEANDRO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-leandro`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-leandro",
  },
  FRANCO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-franco`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-franco",
  },
  BRUNO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-bruno`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-bruno",
  },
  JOAQUIN: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-joaquin`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-joaquin",
  },
  NACHO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-nacho`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-nacho",
  },
  GIOVANA: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-giovana`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-giovana",
  },
  WALDEMAR: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-waldemar`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-waldemar",
  },
  ALEJO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-alejo`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-alejo",
  },
  SIMON: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-simon`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-simon",
  },
  NICOLAS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-nicolas`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-nicolas",
  },
  ESTEBAN: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-esteban`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-esteban",
  },
  SHANE: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-shane`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-shane",
  },
  PABLOM: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-pablom`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-pablom",
  },
  JUANI: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-juani`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-juani",
  },
  PABLOBA: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-pabloba`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-pabloba",
  },
  JULIAN: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-julian`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-julian",
  },
  MARCOS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-marcos`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-marcos",
  },
  MELANY: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-melany`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-melany",
  },
  MATIAS: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-matias`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-matias",
  },
  JULIO: {
    LO_FRONT_URL: "https://test.lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/dev-juliochort`,
    SMARTY_KEY: "118833480137188122",
    ENV: "dev-juliochort",
  },
  PROD: {
    LO_FRONT_URL: "https://lo.nftydoor.com",
    API_URL: `https://api.app.nftydoor.com/prod`,
    SMARTY_KEY: "118833480137188122",
    ENV: "prod",
  },
};

export const ENV = ((localStorage.getItem("currentEviroment") as string) ??
  "DEV") as keyof typeof env;

function getEnvVariable(key: keyof typeof env.DEV) {
  const isInProduction = process.env.REACT_APP_ENV === "prod";
  return env[isInProduction ? "PROD" : ENV][key];
}

export default getEnvVariable;
